import { IProject } from "@/lib/model";
import { loadProjects } from '@/lib/utils';
import { OnShare } from "@/routes/Share";
import {
  Box,
  Center,
  Flex,
  Heading,
  HStack,
  Link,
  Select,
  SimpleGrid,
  Text,
  Tooltip,
  useDisclosure,
  useToast,
  VStack
} from '@chakra-ui/react';
import { Download, ExternalLink, FileVideo, Images, Info, Megaphone, Share } from "lucide-react";
import React, { useEffect, useState } from "react";
import { Preview } from "./Preview";


export default function Builds() {
  const [projects, setProjects] = useState<IProject[]>([])
  const [filteredProjects, setFilteredProjects] = useState<IProject[]>([])
  const [categories, setCategories] = useState<string[]>([])
  const [categoriesCount, setCategoriesCount] = useState<{ [key: string]: number }>({})
  const [showStages, setShowStages] = useState<boolean>(true)
  const [stages, setStages] = useState<string[]>([])
  const [stagesCount, setStagesCount] = useState<{ [key: string]: number }>({})
  const [selectedCategory, setSelectedCategory] = useState<string>("All")
  const [selectedStage, setSelectedStage] = useState<string>("All")
  const [selectedBuild, setSelectedBuild] = useState<IProject | null>(null)
  const [selectedBuildSection, setSelectedBuildSection] = useState<string>("")
  const { isOpen, onOpen, onClose } = useDisclosure()
  const leastDestructiveRef = React.useRef(null)


  useEffect(() => {
    loadProjects()
      .then(data => {
        const projectsData = data as IProject[]
        setProjects(projectsData)
        setFilteredProjects(projectsData)
        // const uniqueCategories = Array.from(new Set(projectsData.map(p => p.category)))
        // const uniqueStages = Array.from(new Set(projectsData.map(p => p.stage)))

        // Set categories
        const uniqueCategories = ["Applied AI", "Robotics", "Divider", "Creatives", "Experiments", "Models", "Tooling"]
        for (const category of uniqueCategories) {
          setCategoriesCount(prev => ({ ...prev, [category]: projectsData.filter(p => p.category === category).length }))
        }
        setCategories(["All", ...uniqueCategories])

        // Set stages
        const uniqueStages = ["Prototype", "Alpha", "Beta", "Release"]
        for (const stage of uniqueStages) {
          setStagesCount(prev => ({ ...prev, [stage]: projectsData.filter(p => p.stage === stage).length }))
        }
        setStages(["All", ...uniqueStages])
      })
  }, [])

  useEffect(() => {
    setFilteredProjects(projects.filter(project =>
      (selectedCategory === "All" || project.category === selectedCategory) &&
      (selectedStage === "All" || project.stage === selectedStage)
    ))
  }, [selectedCategory, selectedStage, projects])

  function showPreview(project: IProject) {
    setSelectedBuild(project)
    onOpen()
  }

  function handleCategoryChange(category: string) {
    if (selectedCategory === category) {
      setSelectedCategory("All")
    } else {
      setSelectedCategory(category)
    }
  }

  function handleStageChange(stage: string) {
    if (selectedStage === stage) {
      setSelectedStage("All")
    } else {
      setSelectedStage(stage)
    }
  }

  return (
    <Center
      width="100%"
      mb={20}
    >
      <VStack
        width="100%"
      >
        <Heading
          as={"h1"}
          size={"3xl"}
          color={"#004AAD"}
          my={6}
        >
          Builds
        </Heading>

        <VStack spacing={4} mb={4}>
          <HStack>
            <Text fontSize="lg" color="gray.500" width="100px">Category</Text>
            <Select
              value={selectedCategory}
              onChange={(e) => handleCategoryChange(e.target.value)}
              width="200px"
            >
              {categories.map((category, index) => (
                category === "Divider" ? <option disabled>──────────</option> : (
                  <option key={index} value={category}>{category}{categoriesCount[category] > 0 && ` (${categoriesCount[category]})`}</option>
                )
              ))}
            </Select>
          </HStack>

          {showStages && (
            <HStack>
              <Text fontSize="lg" color="gray.500" width="100px">Stage</Text>

              <Select
                value={selectedStage}
                onChange={(e) => handleStageChange(e.target.value)}
                width="200px"
              >
                {stages.map((stage, index) => (
                  <option key={index} value={stage}>{stage}{stagesCount[stage] > 0 && ` (${stagesCount[stage]})`}</option>
                ))}
              </Select>
            </HStack>
          )}
        </VStack>

        <Text fontSize="xl" color="gray.500" mb={4}>
          {filteredProjects.length} results
        </Text>

        {filteredProjects.map((project, index) => (
          <SimpleGrid
            key={index}
            columns={2}
            p={4}
            boxShadow="sm"
            borderRadius="lg"
            _hover={{ boxShadow: "md" }}
            transition="box-shadow 0.2s"
            cursor="pointer"
            width="100%"
            className="hover:scale-105 transition-all duration-300 ease-in-out"
          >
            <Flex
              alignItems='center'
              justifyContent={"flex-end"}
              textAlign="right"
              pr={4}
              onClick={() => showPreview(project)}
            >
              <Text fontWeight="medium" fontSize="lg">{project.date}</Text>
            </Flex>

            <VStack
              textAlign="left"
              pl={4}
              py={2}
              borderLeftWidth="2px"
              borderColor="gray.300"
            >
              <div className={"min-w-full h-auto"} onClick={() => showPreview(project)}>
                <BuildHeading title={project.title} subtitle={project.subtitle} logo={project.logo} />
              </div>

              <div className={"min-w-full h-auto"}>
                <BuildLinks project={project} showBuild={showPreview} setBuildSection={setSelectedBuildSection} />
              </div>

              <div className={"min-w-full h-auto"}>
                <BuildLabels
                  category={project.category}
                  stage={project.stage}
                  selectedCategory={selectedCategory}
                  selectedStage={selectedStage}
                  handleCategoryChange={handleCategoryChange}
                  handleStageChange={handleStageChange}
                />
              </div>
            </VStack>
          </SimpleGrid>
        ))}

        {selectedBuild && (
          <Preview
            selectedProject={selectedBuild}
            selectedSection={selectedBuildSection}
            isOpen={isOpen}
            onClose={onClose}
            leastDestructiveRef={leastDestructiveRef}
          />
        )}
      </VStack>
    </Center>
  )
}

interface BuildHeadingProps {
  title: string
  subtitle: string
  logo?: string
}

function BuildHeading({ title, subtitle, logo }: BuildHeadingProps) {
  return (
    <Box>
      <Heading
        as={"h1"}
        fontFamily="AlegreyaSans-Black"
        fontSize="3xl"
        fontWeight="semibold"
        color={"#004AAD"}
        className="hover:scale-105 transition-all hover:translate-x-3 duration-300 ease-in-out"
      >
        <span className={"text-2xl inline-flex items-center"}>{logo && <img src={logo} alt={title} className={"h-8 w-8 mr-2"} />}{title}</span>
      </Heading>

      <Text
        fontFamily="AlegreyaSans-Black"
        fontSize="md"
        color="gray.600"
        mt={2}
      >
        {subtitle}
      </Text>
    </Box>
  )
}

interface BuildLinksProps {
  project: IProject

  showBuild: (project: IProject) => void
  setBuildSection: (section: string) => void
}

function BuildLinks({ project, showBuild: showPreview, setBuildSection }: BuildLinksProps) {
  const toast = useToast()

  return (
    <Box>
      <div className={"flex flex-row gap-2"}>
        <Tooltip label={"Info"}>
          <div
            onClick={() => {
              showPreview(project)
              setBuildSection("")
            }}
          >
            <Info />
          </div>
        </Tooltip>

        {project.pictures && project.pictures?.length > 0 && (
          <Tooltip label={"Browser gallery"}>
            <div onClick={() => {
              showPreview(project)
              setBuildSection("gallery")
            }}>
              <Images />
            </div>
          </Tooltip>
        )}

        {project.video && (
          <Tooltip label={"Watch video"}>
            <div onClick={() => {
              showPreview(project)
              setBuildSection("video")
            }}>
              <FileVideo />
            </div>
          </Tooltip>
        )}

        {project.post && (
          <Tooltip label={"Read it"}>
            <Link
              href={`https://x.com/zeffmuks/status/${project.post}`}
              isExternal
              _hover={{ textDecoration: 'none' }} // Remove underline on hover
            >
              <Megaphone />
            </Link>
          </Tooltip>
        )}

        {project.downloadItLink && (
          <Tooltip label={"Download it"}>
            <Link
              href={project.downloadItLink}
              isExternal
              _hover={{ textDecoration: 'none' }} // Remove underline on hover
            >
              <Download />
            </Link>
          </Tooltip>
        )}

        {project.tryItOutLink && (
          <Tooltip label={"Try it"}>
            <Link
              href={project.tryItOutLink}
              isExternal
              _hover={{ textDecoration: 'none' }} // Remove underline on hover
            >
              <ExternalLink />
            </Link>
          </Tooltip>
        )}

        <Tooltip label={"Share it"}>
          <Share onClick={() => OnShare({
            title: project.title,
            text: project.description,
            url: `${window.location.origin}/project/${project.id}`,
            toast
          })} />
        </Tooltip>
      </div>
    </Box>
  );
}

interface BuildLabelsProps {
  category: string
  stage: string

  selectedCategory: string
  selectedStage: string

  handleCategoryChange: (category: string) => void
  handleStageChange: (stage: string) => void
}

function BuildLabels({ category, stage, selectedCategory, selectedStage, handleCategoryChange, handleStageChange }: BuildLabelsProps) {
  return (
    <HStack>
      <p
      className={`text-sm text-gray-500 rounded-full px-1 py-1 cursor-pointer hover:bg-blue-100 ${selectedCategory === category ? "bg-blue-100" : "bg-gray-100"}`}
        onClick={() => handleCategoryChange(category)}
      >
        {category}
      </p>
      <p
        className={`text-sm text-gray-500 rounded-full px-1 py-1 cursor-pointer hover:bg-blue-100 ${selectedStage === stage ? "bg-blue-100" : "bg-gray-100"}`}
        onClick={() => handleStageChange(stage)}
      >
        {stage}
      </p>
    </HStack>
  )
}
