import { IProject } from "@/lib/model";
import { Box, useDisclosure } from "@chakra-ui/react";
import { useEffect, useRef, useState } from "react";
import { useParams } from "react-router-dom";
import { Preview } from "./Preview";
import { loadProjects } from "@/lib/utils";

export default function Share() {
  const [selectedProject, setSelectedProject] = useState<IProject | null>(null)
  const [isModalOpen, setIsModalOpen] = useState(false); // Set isOpen to true initially
  const { onClose } = useDisclosure()

  const { id } = useParams()

  const leastDestructiveRef = useRef()

  useEffect(() => {
    loadProjects()
      .then(data => {
        const project = data.find((project: IProject) => project.id === parseInt(id!))

        setSelectedProject(project!)
        setIsModalOpen(true)
      })
  }, [id])

  return (
    <Box width={"100%"} height={"100vh"}>
      <Box p={20} width={"100%"} height={"100vh"} className={"bg-white"}>
        {selectedProject && (
          <Preview
            selectedProject={selectedProject!}
            selectedSection={""}
            isOpen={isModalOpen}
            onClose={onClose}
            leastDestructiveRef={leastDestructiveRef}
          />
        )}
      </Box>
    </Box>
  )
}