import { Box, Center, Flex, Heading, Image, Link, useBreakpointValue, VStack } from "@chakra-ui/react";
import { CircleArrowDown } from "lucide-react";
import { useState } from "react";
import { Helmet } from 'react-helmet-async';
import Builds from "./Builds";


export default function Home() {
  const [subtitle, setSubtitle] = useState<string[]>([
    "Antifragile Entropy Assassin",
    "🥷"
  ])
  const [industries, setIndustries] = useState<string[]>([
    "Applied AI",
    "Robotics",
    "Space Tech"
  ])
  const [exploreCta, setExploreCta] = useState<string>("Explore")

  const separators = [
    ", ",
    " & "
  ]
  const originalIndustries = [
    "Applied AI",
    "Robotics",
    "Space Tech"
  ]
  const expandedIndustries = [
    "Applied Artificial Intelligence",
    "General Robotics",
    "Space Exploration Tech"
  ]

  const handleSubtitleMouseEnter = (key: number) => {
    const flippedSubtitle = [
      "🥷",
      "Antifragile Entropy Assassin"
    ]
    setSubtitle(key === 1 ? flippedSubtitle : [
      "Antifragile Entropy Assassin",
      "🥷"
    ])
  }

  const handleIndustryMouseEnter = (key: number) => {
    document.body.style.cursor = "pointer"
    const currentIndustries: string[] = []
    for (let i = 0; i < originalIndustries.length; i++) {
      currentIndustries.push(key === i ? expandedIndustries[i] : originalIndustries[i])
    }
    setIndustries(currentIndustries)
  }

  const handleIndustryMouseLeave = () => {
    document.body.style.cursor = "default"
    setIndustries(originalIndustries)
  }

  return (
    <Box>
      <Helmet>
        <meta property="og:type" content="website" />
        <meta property="og:title" content={"Zeff Muks"} />
        <meta property="og:description" content={"Antifragile Entropy Assassin 🥷"} />
        <meta property="og:url" content={window.location.href} />
        <meta property="og:image" content={"https://www.zeffmuks.com/images/ZeffMuks-1920.png"} />
        <meta property="og:site_name" content="Zeff Muks" />

        <meta name="twitter:card" content="summary_large_image" />
        <meta name="twitter:site" content="@zeffmuks" />
        <meta name="twitter:title" content="Zeff Muks" />
        <meta name="twitter:description" content="Antifragile Entropy Assassin 🥷" />
        <meta name="twitter:image" content={"https://www.zeffmuks.com/images/ZeffMuks-1920.png"} />
      </Helmet>

      <Flex
        width="100%"
        height="100vh"
        direction="column"
        position="relative"
      >
        <Center flex={1}>
          <VStack
            textAlign="center"
          >
            <Heading
              as="h1"
              size={{ base: "xl", sm: "2xl", md: "3xl", lg: "4xl" }}
              mt={5}
            >
              {subtitle.map((subtitle, index) => (
                <span
                  key={index}
                  onMouseEnter={() => {
                    handleSubtitleMouseEnter(index)
                  }}
                >
                  {subtitle}
                  {index < subtitle.length - 1 && " "}
                </span>
              ))}
            </Heading>

            <Image
              src={"/images/ZeffMuks-6912.png"}
              className="hover:scale-105 transition-all duration-300"
            />

            <Heading
              as="h3"
              size={{ base: "md", sm: "lg", md: "xl", lg: "2xl" }}
            >
              {industries.map((industry, index) => (
                <span
                  key={index}
                  onMouseEnter={() => {
                    handleIndustryMouseEnter(index)
                  }}
                  onMouseLeave={() => {
                    handleIndustryMouseLeave()
                  }}
                  className="text-gray-600"
                >
                  <span
                    className="hover:text-blue-500 transition-all duration-250"
                  >
                    {industry}
                  </span>
                  {index < industries.length - 1 && separators[index]}
                  {index < industries.length - 1 && " "}
                </span>
              ))}
            </Heading>

            <Heading
              as="h2"
              size="2xl"
            >
              <Link href={"https://x.com/zeffmuks"}>
                𝕏
              </Link>
            </Heading>
          </VStack>
        </Center>

        <VStack
          position="absolute"
          bottom="3rem"
          left="0"
          right="0"
        >
          <Heading
            as="h3"
            size={{ base: "md", sm: "lg", md: "xl", lg: "2xl" }}
            color={"#004AAD"}
            className="hover:scale-110 transition-all duration-300"
            onMouseEnter={() => {
              setExploreCta("🌊 Dive into the Future 🔮")
            }}
            onMouseLeave={() => {
              setExploreCta("Explore")
            }}
          >
            {exploreCta}
          </Heading>

          <CircleArrowDown
            size={useBreakpointValue({ base: 21, sm: 28, md: 35, lg: 42 })}
            color={"#004AAD"}
            className="hover:scale-110 transition-all duration-300"
            onClick={() => {
              document.getElementById("builds")?.scrollIntoView({
                behavior: "smooth"
              })
            }}
          />
        </VStack>
      </Flex>

      <section id="builds">
        <Builds />
      </section>
    </Box>
  )
}
