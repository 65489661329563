import React from "react";
import {Button, useToast} from "@chakra-ui/react";

export interface ShareProps {
    title: string
    text: string
    url: string
    toast?: ReturnType<typeof useToast>
}

export async function OnShare({title, text, url, toast}: ShareProps) {
    if (!toast) {
        console.error("No toast provided")
        return
    }

    console.log("Sharing: ", title, text, url)

    if (navigator.share) {
        try {
            await navigator.share({title, text, url});
        } catch (error) {
            console.error("Error sharing:", error);
        }
    } else {


        // Fallback: Copy the link to the clipboard
        try {
            await navigator.clipboard.writeText(url);
            console.log("Link copied to clipboard:", url);

            toast({
                title: "Link copied to clipboard",
                status: "success",
                duration: 3000,
                isClosable: true,
            })
        } catch (error) {
            console.error("Error copying link to clipboard:", error);
        }
    }
}

export function ShareButton({title, text, url, toast}: ShareProps) {
    return (
        <Button
            onClick={() => OnShare({title, text, url, toast})}
            p={2}
            mb={4}
            ml={2}
        >
            Share
        </Button>
    );
}
