import { IProject } from '@/lib/model'
import { type ClassValue, clsx } from "clsx"
import yaml from 'js-yaml'
import { twMerge } from "tailwind-merge"

export function cn(...inputs: ClassValue[]) {
  return twMerge(clsx(inputs))
}


export async function loadProjects(): Promise<IProject[]> {
  try {
    const response = await fetch('/projects.yml')
    const text = await response.text()
    const data = yaml.load(text)

    if (!Array.isArray(data)) {
      console.error('Loaded data is not an array:', data)
      return []
    }

    return data as IProject[]
  } catch (error) {
    console.error('Error loading projects:', error)
    return []
  }
}